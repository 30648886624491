<template>
    <div>
        <div class="row setup-content" id="cpnfirm-data">
            <div class="col-sm-12">
                <div class="col-md-12 p-0">
                    <h3 class="mb-4 text-start">Finish:</h3>
                    <div class="row justify-content-center">
                    <div class="col-3"> <img src="@/assets/images/page-img/img-success.png" class="img-fluid" alt="img-success"></div>
                    </div>
                </div>
                <button type="button" class="btn btn-secondary" data-enchanter="previous">Previous</button>
                <button type="button" class="btn btn-primary float-end" data-enchanter="finish">Finish</button>
            </div>
        </div>
    </div>
</template>
